/* You can add global styles to this file, and also import other style files */

@import "https://js.arcgis.com/4.28/@arcgis/core/assets/esri/themes/light/main.css";


/* quill */

.ql-snow .ql-editor .ql-font-sans-serif{
    font-family: var(--font-light);
}
.ql-snow .ql-editor .ql-font-serif{
    font-family: var(--font-heading);
}
.ql-snow .ql-editor .ql-font-monospace{
    font-family: var(--font-monospace);
}
.ql-snow .ql-editor .ql-video{
    width: 100%;
    aspect-ratio: 16/9;
    margin: 1rem 0;
}
.ql-snow .ql-editor img{
    margin: 1rem 0;
}
