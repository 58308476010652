.grid-list {
    --icon-size: 0;
    --row-gap: 0;
    --col-gap: 0;
    list-style: none;
    padding: 0;
}
.grid-list-item {
    display: grid;
    row-gap: var(--row-gap);
    column-gap: var(--col-gap);
}
.list{
    margin: 0;
    padding: 0;
    list-style: none;
}
.grid-list-triple{
    --gap-col: 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap-col);
    align-items: start;
}
.grid-list-triple .grid-list-item{
    --gap-row: 0.75rem;
    justify-items: center;
    gap: var(--gap-row);
    text-decoration: none;
}