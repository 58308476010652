.link:hover{
    transition: var(--transition);
}
.link-heading,
.link-text{
    color: var(--clr-text);
    text-decoration: none;
    transition: var(--transition);
    cursor: pointer;
}
.link-text:hover{
    text-decoration: underline;
}
.link-text.active-link{
    font-weight: bold;
}
.link-text.active-link:hover{
    text-decoration: none;
}

/* Link img */
.link-img{
    display: flex;
}
.link-img:hover > img{
    opacity: 0.92;
}


/* Link text-icon */
.link-icon{
    padding:0.5rem 1.5rem 0.5rem 0.5rem;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: var(--transition);
    color: var(--clr-text);
    text-decoration: none;
}
.link-icon::after{
    content: "";
    display: block;
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    opacity: 0;
    top: 0.5em;
    right: 0;
    background-repeat: no-repeat;
    background-size: cover;
    transition: var(--transition);
}
.link-icon:hover{
    text-decoration: underline;
}
.link-icon:hover::after{
    opacity: 1;
    top: 0.6em;
}
.link-icon.icon-download::after{
    background-image: url(../assets/icons/ico-download.svg);
}
.link-icon.icon-map::after{
    background-image: url(../assets/icons/ico-map.svg);
    transform: scale(0.86) translateX(12%);
}
.link-icon.icon-site::after{
    background-image: url(../assets/icons/chevron_right.svg);
}

