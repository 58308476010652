.btn {
  /* Reset */
  --btn-idle-fill: var(--clr-text-invert);
  --btn-fill-hover: rgba(255, 255, 255, 0.16);
  --btn-fill-active: rgba(255, 255, 255, 0.4);
  --clr: var(--btn-idle-fill);

  font-family: var(--font-body);
  font-size: 1rem;
  line-height: 1.5;
  background: none;
  border: none;

  /* Styles */
  border: 1px solid transparent;
  flex: none;
  transition: var(--transition);
  cursor: pointer;
}
a.btn{
  display: inline-flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn.outlined {
  min-height: 2.5rem;
  max-width: 100%;
  min-width: 11.25rem;
  padding: 0 1rem;
  border-radius: 2.5rem;
  border-color: var(--clr);
  color: var(--clr);
}
.btn.outlined:hover {
  background: var(--btn-fill-hover);
}
.btn.outlined:active {
  background: var(--btn-fill-active);
}

.btn.icon{
  --btn-idle-fill: var(--clr-primary);
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--clr);
}
.btn.icon:hover{
  --btn-idle-fill: var(--clr-accent);
}

.btn:active {
  transform: translateY(1px);
}

.dark{
  --btn-idle-fill: var(--clr-text);
  --btn-fill-hover: rgba(0, 0, 0, 0.08);
  --btn-fill-active: rgba(0, 0, 0, 0.24);
}

.btn:disabled{
  --btn-idle-fill: var(--clr-disabled);
  pointer-events: none;
}