/* Section */
.section-content {
  display: flex;
  flex-direction: column;
  gap: var(--gap-md);
  padding:var(--gap-lg) 0;
}
.section-grid-content {
  display: grid;
  flex-direction: column;
  gap: var(--gap-md);
  padding:var(--gap-lg) 0;
}
.section-col{
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
}
.section-row{
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-xs);
}
.section-header {
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  padding: 0 0 var(--gap-sm);
}
#sectionStart{
  position: relative;
  top:calc( -1 * var(--header-height));
}

/* Buttons */
.section-buttons{
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-md);
  padding: var(--gap-md) 0 var(--gap-xs);
  min-height: 7.25rem;
}
@media screen and (max-width: 480px) {
  .section-buttons{
    justify-content: center;
    gap: var(--gap-sm);
  }
}

/* Width */
.width-md{
  width: 100%;
  max-width: var(--width-md);
}
.width-sm{
  width: 100%;
  max-width: var(--width-sm);
}
.width-xs{
  width: 100%;
  max-width: var(--width-xs);
}

/* Divider */
.divider{
  border: 1px solid black;
}
.divider-lg{
  background-color: var(--clr-primary);
  height: 7rem;
  border: none;
}
.divider-lg.pattern{
  background-color: var(--clr-background);
  background-image: url(../assets/images/pattern-h.svg);
  background-position: center;
  height: var(--gap-lg);
}

.pattern-start{
  position: relative;
  background-image: url(../assets/images/pattern-v.svg);
  background-position: left center;
  background-repeat: repeat-y;
  background-attachment: fixed;
}

/* Bacground */
.clr-background{
  background: var(--clr-background);
}
.clr-background-transparent{
  background: var(--clr-background-transparent);
}
.clr-background-secondary{
  background: var(--clr-background-secondary);
}
.clr-background-primary{
  background: var(--clr-primary);
}
.clr-background-primary-transparent{
  background: var(--clr-primary-transparent);
}

.d-block{
  display: block;
}

/* Gap */
.gap-none{
  gap: 0;
}
.gap-xs{
  gap:var(--gap-xs);
}
.gap-sm{
  gap:var(--gap-sm);
}
.gap-md{
  gap:var(--gap-md);
}
.gap-lg{
  gap:var(--gap-lg);
}
.gap-xl{
  gap:var(--gap-xl);
}

/* Pading */
.p-sm{
  padding: var(--gap-sm);
}
.p-md{
  padding: var(--gap-md);
}
.p-lg{
  padding: var(--gap-lg);
}
.pe-lg{
  padding-right: var(--gap-lg);
}
.pb-2lg{
  padding-bottom: calc(2 * var(--gap-lg));
}

/* Margin */
.ms-auto{
  margin-left: auto;
}

/* Border */
.border{
  border:1px solid var(--clr-border);
}