:root {
  --min-width: 320px;
  --max-width: 1600px;
  --header-height: 96px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.grid-container {
  --flap: minmax(1rem, 1fr);
  --content: minmax(var(--min-width), var(--max-width));

  display: grid;
  grid-template-columns:
    var(--flap)
    var(--content)
    var(--flap);
}
.grid-content {
  grid-column: 2 / 3;
}
.grid-content-full {
  grid-column: 1 / -1;
}

.grid-double-column{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap-xl)
}
@media screen and (max-width: 1130px) {
  .grid-double-column {
      gap: var(--gap-md);
  }
}
@media screen and (max-width: 860px) {
  :root {
    --header-height: 64px;
  }
}
@media screen and (max-width: 700px) {
  .grid-double-column {
      grid-template-columns: 1fr;
      padding: var(--gap-xs);
  }
}
.placement-center {
  align-self: center;
  align-items: center;
}