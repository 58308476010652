:root{
    --clr-deep-teal:#113A36;
    --clr-primary:#067D45;
    --clr-primary-transparent:rgba(6, 125, 69, 0.96);

    --clr-background:white;
    --clr-background-secondary:#f5f5f5;
    --clr-background-transparent:#ffffffee;
    --clr-background-accent:#d0e1c2;
    --clr-background-invert:black;

    --clr-border:#eee;
    --clr-border-active:#bdbdbd;

    --clr-disabled:#bdbdbd;

    --clr-text:black;
    --clr-text-invert:white;
   
    /* WIDTH */
    --width-full: 100%;
    --width-md: 1080px;
    --width-sm: 756px;
    --width-xs: 480px;

    /* GAPS */
    --gap-xs: 1rem;
    --gap-sm: 2rem;
    --gap-md: 3.75rem;
    --gap-lg: 6rem;
    --gap-xl: 12rem;

    /* TRANSITION */
    --transition: all .2s ease;

    /* SHADOW */
    --shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.24);
    --shadow-hover: 2px 2px 12px 0px rgba(0, 0, 0, 0.24);
    --text-shadow: black 0 0 4px;
}
@media screen and (max-width: 640px) {
    :root{
        /* GAPS */
        --gap-xs: 0.8125rem;
        --gap-sm: 1.625rem;
        --gap-md: 3rem;
        --gap-lg: 4.875rem;
        --gap-xl: 9.75rem;
    }
}