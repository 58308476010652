@font-face {
  font-family: eUkraineThin;
  src: url(../assets/fonts/e-Ukraine-Thin.eot);
  src: url(../assets/fonts/e-Ukraine-Thin.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-Thin.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-Thin.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-Thin.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineUltraLight;
  src: url(../assets/fonts/e-Ukraine-UltraLight.eot);
  src: url(../assets/fonts/e-Ukraine-UltraLight.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-UltraLight.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-UltraLight.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-UltraLight.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineLight;
  src: url(../assets/fonts/e-Ukraine-Light.eot);
  src: url(../assets/fonts/e-Ukraine-Light.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-Light.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-Light.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-Light.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineRegular;
  src: url(../assets/fonts/e-Ukraine-Regular.eot);
  src: url(../assets/fonts/e-Ukraine-Regular.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-Ukraine-Regular.woff2) format("woff2"),
       url(../assets/fonts/e-Ukraine-Regular.woff) format("woff"),
       url(../assets/fonts/e-Ukraine-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: eUkraineHeadRegular;
  src: url(../assets/fonts/e-UkraineHead-Regular.eot);
  src: url(../assets/fonts/e-UkraineHead-Regular.eot?#iefix) format("embedded-opentype"),
       url(../assets/fonts/e-UkraineHead-Regular.woff2) format("woff2"),
       url(../assets/fonts/e-UkraineHead-Regular.woff) format("woff"),
       url(../assets/fonts/e-UkraineHead-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap
}

:root{
  --rem: 16px;
  --font-body:'eUkraineLight';

  --font-regular:'eUkraineRegular';
  --font-light:'eUkraineLight';
  --font-ultra-light:'eUkraineUltraLight';
  --font-thin:'eUkraineThin';
  
  --font-heading:'eUkraineHeadregular';
}
html{
  font-size: var(--rem);
}
body{
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  font-family: var(--font-body), sans-serif, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 640px) {
  body {
    font-size: 0.8125rem;
  }
}

/* HEADING */
.heading-xl,
.heading-lg,
.heading-md,
.heading-sm,
.heading-xs{
  font-family: var(--font-heading);
}

.heading-xl {
  font-size: 3.5rem;
  line-height: 4rem;
  font-weight: bold;
}
.heading-lg {
  font-size: 2.375rem;
  line-height: 2.5rem;
  font-weight: bold;
}
.heading-md {
  font-size: 1.75rem;
  line-height: 2rem;
}
.heading-sm {
  font-size: 1.5rem;
  line-height: 1.75rem;
}
.heading-xs{
  font-size: 1.25rem;
  line-height: 1.5rem;
}

@media screen and (max-width: 640px) {
  .heading-xl {
    font-size: 1.625rem;
    line-height: 1.75rem;
  }
  .heading-lg {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
  .heading-md {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .heading-sm {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .heading-xs{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

/* BODY */
.body-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.body-md {
  font-size: 1rem;
  line-height: 1.5rem;
}
.body-sm {
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.body-xs {
  font-size: 0.8125rem;
  line-height: 1.125rem;
}
.body-xxs {
  font-size: 0.75rem;
  line-height: 1rem;
}
@media screen and (max-width: 640px) {
  .body-lg {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .body-md {
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }
  .body-sm {
    font-size: 0.75rem;
    line-height: 0.875rem;
  }
  .body-xs {
    font-size: 0.6875rem;
    line-height: 0.875rem;
  }
  .body-xxs {
    font-size: 0.625rem;
    line-height: 0.875rem;
  }
}

/* Font */
.font-body{
  font-family: var(--font-body);
}
.font-thin{
  font-family: var(--font-thin);
}
.font-ultra-light{
  font-family: var(--font-ultra-light);
}
.font-light{
  font-family: var(--font-light);
}
.font-regular{
  font-family: var(--font-regular);
}
.font-heading{
  font-family: var(--font-heading);
}

/* Text */
.text-uppercase{
  text-transform: uppercase;
}
.align-center{
  text-align: center;
}
.break-word{
  word-break: break-word;
}

/* Gutter */
.gutter-md {
  margin-bottom: 2rem;
}
.gutter {
  margin-bottom: 1rem;
}


/* Colors */
.clr-text{
  color: var(--clr-text);
}
.clr-text-invert{
  color: var(--clr-text-invert);
}
.text-shadow{
  text-shadow: var(--text-shadow);
}